<template>
  <!-- Popup (下拉選單) -->
  <section
    id="popDropdown"
    :class="['popup', popTime ? 'popTime point_history' : 'popDropdown']"
    @click.prevent="closePopup"
  >
    <div class="popBody" @click.stop>
      <a @click.prevent="closePopup" class="close"
        ><font-awesome-icon icon="fas fa-times"></font-awesome-icon>
      </a>
      <slot :close="closePopup"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FilterPopup',
  props: ['popTime'],
  data() {
    return {};
  },
  mounted() {
    popDropdown.style.display = 'flex';
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'scroll';
  },
  computed: {
  },
  methods: {
    closePopup() {
      popDropdown.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        this.$emit('closePopup');
        popDropdown.children[0].style.animationName = 'popup';
      }, 300)
    },
  },
};
</script>
